import * as React from 'react';

import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import type { OutlinedInputProps } from '@mui/material/OutlinedInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import type { Control, FieldValues, Path } from 'react-hook-form';
import { useController } from 'react-hook-form';

export type TextFieldElementProps<T extends FieldValues> = Omit<OutlinedInputProps, 'name'> & {
  name: Path<T>;
  control: Control<T>;
  label: string;
  helperText?: string;
};

const TextFieldElement = <T extends FieldValues>({ control, name, required, helperText, ...others }: TextFieldElementProps<T>) => {
  const { field, fieldState } = useController({
    name,
    control,
  });

  const id = React.useId();

  return (
    <FormControl required={required} error={!!fieldState.error} variant="outlined" fullWidth margin="dense">
      <InputLabel htmlFor={id}>{others.label}</InputLabel>
      <OutlinedInput {...field} {...others} id={id} />
      <FormHelperText>{fieldState.error ? fieldState.error.message : (helperText ?? ' ')}</FormHelperText>
    </FormControl>
  );
};

export default TextFieldElement;
