import type { TRPCClientErrorLike } from '@trpc/client';
import type { AnyTRPCRouter } from '@trpc/server';
import type { FieldValues, Path, UseFormSetError } from 'react-hook-form';

import dynamic from 'next/dynamic';

import { logger } from '@pegase/helpers/utils';

export const ERROR_SEPARATOR = '%|%';

export const parseFormErrors = <T extends FieldValues, R extends AnyTRPCRouter>(
  setError: UseFormSetError<T>,
  errors: TRPCClientErrorLike<R>,
) => {
  if (errors.data?.code === 'BAD_REQUEST') {
    if (errors.data?.zodError?.formErrors) {
      setError('root', {
        type: 'validate',
        message: errors.data?.zodError?.formErrors.join(ERROR_SEPARATOR),
      });
    }

    Object.keys(errors.data?.zodError?.fieldErrors).map((field) => {
      setError(field as Path<T>, {
        type: '400',
        message: errors.data?.zodError?.fieldErrors[field][0],
      });
    });
  } else {
    logger(errors.data?.code, errors.message, 'error');
    setError('root', { type: '500', message: errors.message });
  }
};

export const FormTool: React.ElementType = dynamic(() => import('@hookform/devtools').then((module) => module.DevTool), {
  ssr: false,
});
