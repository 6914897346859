'use client';

import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import { useSession } from 'next-auth/react';

import DiscordIcon from '~/assets/icons/discord-icon';
import FacebookIcon from '~/assets/icons/facebook-icon';
import GoogleIcon from '~/assets/icons/google-icon';
import { signInAction } from './actions';

interface Props {
  redirectTo: string;
}
const SocialLogin = (props: Props) => {
  const session = useSession();

  return (
    <Stack spacing={1} direction="row">
      <LoadingButton
        onClick={async () =>
          await signInAction('google', { redirectTo: props.redirectTo }).then(() => {
            session.update();
          })
        }
        fullWidth
        sx={{ color: '#CD201F', borderColor: '#CD201F' }}
        variant="soft"
        startIcon={<GoogleIcon />}>
        Google
      </LoadingButton>
      <LoadingButton
        onClick={async () =>
          await signInAction('facebook', { redirectTo: props.redirectTo }).then(() => {
            session.update();
          })
        }
        fullWidth
        sx={{ color: '#4267B2', borderColor: '#4267B2' }}
        variant="soft"
        startIcon={<FacebookIcon />}>
        Facebook
      </LoadingButton>
      <LoadingButton
        onClick={async () =>
          await signInAction('discord', { redirectTo: props.redirectTo }).then(() => {
            session.update();
          })
        }
        fullWidth
        sx={{ color: '#5865F2', borderColor: '#5865F2' }}
        variant="soft"
        startIcon={<DiscordIcon />}>
        Discord
      </LoadingButton>
    </Stack>
  );
};

export default SocialLogin;
