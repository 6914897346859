'use client';

import * as React from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import LoadingButton from '@mui/lab/LoadingButton';
import Grow from '@mui/material/Grow';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/navigation';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { useI18n } from '@pegase/i18n/client';

import { Typography } from '@mui/material';
import OtpFieldElement from '~/shared/form/fields/otp-field-element';
import TextFieldElement from '~/shared/form/fields/text-field-element';
import FormError from '~/shared/form/form-error';
import { signInAction } from './actions';

const magicLinkSchema = z.object({
  email: z.string().email(),
});

const verificationSchema = z.object({
  otp: z.string(),
});

const MagicLink = (props: {
  redirectTo: string;
}) => {
  const t = useI18n();
  const [verificationEmail, setVerificationEmail] = React.useState<string>('');
  const [displayVerificationForm, setDisplayVerificationForm] = React.useState(false);

  const { control, handleSubmit, formState } = useForm<z.infer<typeof magicLinkSchema>>({
    mode: 'onBlur',
    defaultValues: {
      email: '',
    },
    resolver: zodResolver(magicLinkSchema),
  });

  const onSubmit: SubmitHandler<z.infer<typeof magicLinkSchema>> = async (data_form) => {
    await signInAction('resend', {
      email: data_form.email,
    });
    setDisplayVerificationForm(true);
    setVerificationEmail(data_form.email);
  };

  return (
    <>
      {!displayVerificationForm && (
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <FormError errors={formState.errors} />

          <TextFieldElement control={control} autoComplete="email" label={t('common.email')} fullWidth name="email" required />

          <LoadingButton
            fullWidth
            loading={formState.isSubmitting}
            color="primary"
            type="submit"
            disableElevation
            variant="contained"
            sx={{ mt: 2 }}>
            {t('common.continue')}
          </LoadingButton>
        </form>
      )}
      {displayVerificationForm && <VerificationCodeForm email={verificationEmail} redirectTo={props.redirectTo} />}
    </>
  );
};

const VerificationCodeForm = (props: {
  email: string;
  redirectTo: string;
}) => {
  const t = useI18n();
  const session = useSession();
  const router = useRouter();

  const { control, handleSubmit, formState, setError } = useForm<z.infer<typeof verificationSchema>>({
    mode: 'onBlur',
    defaultValues: {
      otp: '',
    },
    resolver: zodResolver(verificationSchema),
  });

  const onSubmit: SubmitHandler<z.infer<typeof verificationSchema>> = async (data_form) => {
    const formattedEmail = encodeURIComponent(props.email.toLowerCase().trim());
    const formattedCode = encodeURIComponent(data_form.otp);
    const formattedCallback = encodeURIComponent(props.redirectTo);
    const otpRequestURL = `/api/auth/callback/resend?email=${formattedEmail}&token=${formattedCode}&callbackUrl=${formattedCallback}`;
    const response = await fetch(otpRequestURL);

    if (response.ok) {
      await session.update();
      router.push(response.url);
    } else {
      setError('otp', { message: t('auth.invalid_code') });
    }
  };

  return (
    <Grow in>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <FormError errors={formState.errors} />

        <Typography variant="subtitle1" sx={{ textAlign: 'left', my: 2 }}>
          {t('auth.verification_code_sent')}
        </Typography>

        <OtpFieldElement control={control} name="otp" />

        <LoadingButton
          fullWidth
          loading={formState.isSubmitting}
          color="primary"
          type="submit"
          disableElevation
          variant="contained"
          sx={{ mt: 2 }}>
          {t('common.confirm')}
        </LoadingButton>
      </form>
    </Grow>
  );
};

export default MagicLink;
